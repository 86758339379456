import type { RawWidgetConfigFragment } from '../gql/fragments/__generated/RawWidgetConfig';
import type { RawWidgetConfigPoiDefFragment } from '../gql/fragments/__generated/RawWidgetConfigPoiDef';
import type { AddressbaseFilter, IntArrayPredicate } from '../gql/schema';
import { WhlModuleType } from '../models/WhlModuleType';

export default (
  widgetConfigRef: MaybeRefOrGetter<RawWidgetConfigFragment>
): Ref<AddressbaseFilter> => {
  const typeConfigRef = useWidgetTypeConfig(
    widgetConfigRef,
    WhlModuleType.Poi
  ) as Ref<RawWidgetConfigPoiDefFragment>;

  return computed((): AddressbaseFilter => {
    const widgetConfig = toValue(widgetConfigRef);
    const baseFilter: AddressbaseFilter = {};

    // client restriction
    if (widgetConfig?.client?.id > 0) {
      baseFilter.client = {
        id: { oneOf: [widgetConfig.client?.id ?? -1] },
      };

      if (widgetConfig.includeSubClients === true) {
        baseFilter.client.includeSubClients = true;
      }
    }

    const typeConfig: RawWidgetConfigPoiDefFragment = toValue(
      typeConfigRef
    ) as RawWidgetConfigPoiDefFragment;
    if (typeConfig) {
      const productlinePredicate: IntArrayPredicate = {};
      // productline (and)
      if (typeConfig.productlinesAnd && !isEmpty(typeConfig.productlinesAnd)) {
        productlinePredicate.allOf = mapToIds(typeConfig.productlinesAnd);
      }

      // productline (or)
      if (typeConfig.productlinesOr && !isEmpty(typeConfig.productlinesOr)) {
        productlinePredicate.oneOf = mapToIds(typeConfig.productlinesOr);
      }

      // productline (not)
      if (
        typeConfig.productlinesIgnore &&
        !isEmpty(typeConfig.productlinesIgnore)
      ) {
        productlinePredicate.noneOf = mapToIds(typeConfig.productlinesIgnore);
      }

      if (!isEmpty(productlinePredicate)) {
        baseFilter.productlines = productlinePredicate;
      }

      // region
      if (typeConfig.regions && !isEmpty(typeConfig.regions)) {
        baseFilter.regions = { oneOf: mapToIds(typeConfig.regions) };
      }

      // location
      if (typeConfig.locations && !isEmpty(typeConfig.locations)) {
        baseFilter.location = { oneOf: mapToIds(typeConfig.locations) };
      }

      // zipcodes
      if (typeConfig.zipcodes && !isEmpty(typeConfig.zipcodes)) {
        const zipCodes = (typeConfig.zipcodes ?? '').split(',');
        if (zipCodes.length > 0) {
          baseFilter.zipcode = { oneOf: zipCodes };
        }
      }

      // coordinates
      if (
        typeConfig.coordinates &&
        typeConfig.coordinates.latitude &&
        typeConfig.coordinates.latitude != 0 &&
        typeConfig.coordinates.longitude &&
        typeConfig.coordinates.longitude != 0 &&
        typeConfig.radius &&
        typeConfig.radius > 0
      ) {
        baseFilter.geoFilter = {
          distanceFromPoint: {
            point: {
              latitude: typeConfig.coordinates.latitude as number,
              longitude: typeConfig.coordinates.longitude as number,
            },
            maxDistance: typeConfig.radius as number,
          },
        };
      }
    }

    return baseFilter;
  });
};
